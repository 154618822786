"use strict";

// STOP SCROLL BOUNCE

// GO TO TOP OF THE PAGE WHEN BACK BUTTON USED

if (history.scrollRestoration) {
  history.scrollRestoration = 'manual';
} else {
  window.onbeforeunload = function () {
      window.scrollTo(0, 0);
  }
}

document.addEventListener("DOMContentLoaded", function(){

// PROJECT OVERVIEW OVERLAY

const projectGrid = document.querySelector('.project-grid');
const footer = document.querySelector('.pg-footer');

const projectOverview = document.querySelector('.project-overview');
const openProjectOverview = document.querySelector('.open');
const closeProjectOverview = document.querySelector('.close'); 
const projectOverviewArrow = document.querySelector('.project-info__arrow');
const projectCopyright = document.querySelector('.project-copyright');

if(openProjectOverview) {
  openProjectOverview.addEventListener('click', function(){
    projectGrid.classList.add('is-hidden');
    this.classList.add('is-active');
    closeProjectOverview.classList.remove('is-active');
    projectOverviewArrow.classList.add('is-rotated');

    if(projectCopyright) {
      projectCopyright.classList.add('is-hidden');
    }

    setTimeout(function() {
      projectOverview.classList.add('is-visible');
      projectGrid.style.display = "none";
      footer.style.display = "none";
    }, 450);
  });

  closeProjectOverview.addEventListener('click', function(){
    this.classList.add('is-active');
    projectOverview.classList.remove('is-visible');
    projectGrid.style.display = "";
    footer.style.display = "";
    openProjectOverview.classList.remove('is-active');
    projectOverviewArrow.classList.remove('is-rotated');

    setTimeout(function() {
      projectGrid.classList.remove('is-hidden');

      if(projectCopyright) {
        projectCopyright.classList.add('is-hidden');
      }

    }, 450);
  });
}

// SCROLLING HEADER

const header = document.querySelector('.pg-header');
let prevScrollPos = window.scrollY || window.pageYOffset;
let isHeaderAtTop = true;

function handleScroll() {
  const currentScrollPos = window.scrollY || window.pageYOffset;

  if (currentScrollPos > prevScrollPos && currentScrollPos > 0) {
    header.classList.remove('is-visible');
    header.classList.add('is-hidden');
  } else {
    header.classList.add('is-visible');
    header.classList.remove('is-hidden');
  }

  if (currentScrollPos <= 0) {
    header.classList.remove('is-hidden');
    header.classList.remove('is-visible');
    header.classList.add('is-static');
    isHeaderAtTop = true;
  } else {
    header.classList.remove('is-static');
    isHeaderAtTop = false;
  }

  prevScrollPos = currentScrollPos;
}

window.addEventListener('scroll', handleScroll);

// PAUSE ANIMATIONS AND VIDEOS UNTIL VISIBLE

function handleIntersection(entries, videoObserver) {
  entries.forEach((entry) => {
    if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
      entry.target.classList.add("is-visible");
      entry.target.play();
    } else {
      entry.target.classList.remove("is-visible");
      entry.target.pause();
    }
  });
}

const videoObserver = new IntersectionObserver(handleIntersection, {
  threshold: 0.5
});

const projectRowAnimations = document.querySelectorAll(".project-row__animation");

projectRowAnimations.forEach((item) => {
  videoObserver.observe(item);
});

const projectRowVideos = document.querySelectorAll(".project-row__video");

projectRowVideos.forEach((item) => {
  videoObserver.observe(item);
});

// SWITCH TO GIF WHEN VISIBLE

function handleGIFIntersection(entries, gifObserver) {
  entries.forEach(entry => {
    var dataGif = entry.target.getAttribute('data-gif');
    var dataSrc = entry.target.getAttribute('data-src');
    if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
      entry.target.src = dataGif;
    } else {
      entry.target.src = dataSrc;
    }
  });
}

var gifObserver = new IntersectionObserver(handleGIFIntersection, {
  threshold: 0.5
});

const projectRowGifs = document.querySelectorAll('.project-row__gif');

projectRowGifs.forEach(item => {
  gifObserver.observe(item);
});

// HOMEPAGE ANIMATIONS

const body = document.querySelector('body');
const lottiePlayer = document.querySelector('.intro-animation');
const headerLogo = document.querySelector('.home .pg-header__logo');
const intro = document.querySelector('.intro-text h1');

const urlPrefix = "https://dev.abbasarezoo.co.uk/bgo";
// const urlPrefix = "http://bgo.aadev";
// const urlPrefix = "";

if(lottiePlayer) {

const theme1DesktopSrc = "/wp-content/uploads/2024/02/BGO_Desktop_01_1920x1080.json";
const theme2DesktopSrc = "/wp-content/uploads/2024/02/BGO_Desktop_02_1920x1080.json";
const theme3DesktopSrc = "/wp-content/uploads/2024/04/BGO_Homepage_03_1920x1080_20240427.json";
const theme4DesktopSrc = "/wp-content/uploads/2024/02/BGO_Desktop_04_1920x1080.json";
const theme5DesktopSrc = "/wp-content/uploads/2024/02/BGO_Desktop_05_1920x1080.json";
const theme6DesktopSrc = "/wp-content/uploads/2024/02/BGO_Desktop_06_1920x1080.json";

const theme1MobileSrc = "/wp-content/uploads/2024/02/BGO_Mobile_01_1179x2556.json";
const theme2MobileSrc = "/wp-content/uploads/2024/02/BGO_Mobile_02_1179x2556.json";
const theme3MobileSrc = "/wp-content/uploads/2024/02/BGO_Mobile_03_1179x2556.json";
const theme4MobileSrc = "/wp-content/uploads/2024/02/BGO_Mobile_04_1179x2556.json";
const theme5MobileSrc = "/wp-content/uploads/2024/02/BGO_Mobile_05_1179x2556.json";
const theme6MobileSrc = "/wp-content/uploads/2024/02/BGO_Mobile_06_1179x2556.json";

const theme1DesktopUrl = urlPrefix + theme1DesktopSrc;
const theme2DesktopUrl = urlPrefix + theme2DesktopSrc;
const theme3DesktopUrl = urlPrefix + theme3DesktopSrc;
const theme4DesktopUrl = urlPrefix + theme4DesktopSrc;
const theme5DesktopUrl = urlPrefix + theme5DesktopSrc;
const theme6DesktopUrl = urlPrefix + theme6DesktopSrc;

const theme1MobileUrl = urlPrefix + theme1MobileSrc;
const theme2MobileUrl = urlPrefix + theme2MobileSrc;
const theme3MobileUrl = urlPrefix + theme3MobileSrc;
const theme4MobileUrl = urlPrefix + theme4MobileSrc;
const theme5MobileUrl = urlPrefix + theme5MobileSrc;
const theme6MobileUrl = urlPrefix + theme6MobileSrc;

const mediaQuery = window.matchMedia('(min-width: 591px)');

function handleViewportChange(event) {
  if (event.matches) {
    if(body.classList.contains('bgo-theme--1')) {
      lottiePlayer.load(theme1DesktopUrl);
    } else if(body.classList.contains('bgo-theme--2')) {
      lottiePlayer.load(theme2DesktopUrl);
    } else if(body.classList.contains('bgo-theme--3')) {
      lottiePlayer.load(theme3DesktopUrl);
    } else if(body.classList.contains('bgo-theme--4')) {
      lottiePlayer.load(theme4DesktopUrl);
    } else if(body.classList.contains('bgo-theme--5')) {
      lottiePlayer.load(theme5DesktopUrl);
    } else if(body.classList.contains('bgo-theme--6')) {
      lottiePlayer.load(theme6DesktopUrl);
    }
  } else {
    if(body.classList.contains('bgo-theme--1')) {
      lottiePlayer.load(theme1MobileUrl);
    } else if(body.classList.contains('bgo-theme--2')) {
      lottiePlayer.load(theme2MobileUrl);
    } else if(body.classList.contains('bgo-theme--3')) {
      lottiePlayer.load(theme3MobileUrl);
    } else if(body.classList.contains('bgo-theme--4')) {
      lottiePlayer.load(theme4MobileUrl);
    } else if(body.classList.contains('bgo-theme--5')) {
      lottiePlayer.load(theme5MobileUrl);
    } else if(body.classList.contains('bgo-theme--6')) {
      lottiePlayer.load(theme6MobileUrl);
    }
  }
}

handleViewportChange(mediaQuery);

mediaQuery.addEventListener("change", handleViewportChange);
}

// Fade in text and logo after animation has finished

if(lottiePlayer) {
lottiePlayer.addEventListener("play", function(){
  setTimeout(() => {
    intro.classList.add('is-visible');
  }, 7000);
  setTimeout(() => {
    headerLogo.classList.add('is-visible');
  }, 8500);
});
}

// PLAY/PAUSE FOR TESTING



});

// Cookies banner

var dropCookie = true;                  // False disables Cookie for testing
var cookieDuration = 7;                // Number of days before cookie expires
var cookieName = 'ssAcceptedCookies';
var cookieValue = 'on';
 
function createDiv(){
    var bodytag = document.getElementsByTagName('body')[0];
    var div = document.createElement('div');
    div.setAttribute('class','cookies-banner');
    // Change href below if your cookie policy page link is not /cookies/
    div.innerHTML = '<p>We use cookies to store anonymous data about your visit. Read more on our <a href="/privacy-policy/" rel="nofollow">privacy policy</a>. <a href="javascript:void(0);" onclick="removeMe();">Close</a></p>';    
    bodytag.insertBefore(div,bodytag.firstChild); // Add the banner just after the opening <body> tag
    createCookie(window.cookieName,window.cookieValue, window.cookieDuration); // Create the cookie
}  
function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000)); 
        var expires = "; expires="+date.toGMTString(); 
    }
    else var expires = "";
    if(window.dropCookie) { 
        document.cookie = name+"="+value+expires+"; path=/"; 
    }
} 
function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
} 
function eraseCookie(name) {
    createCookie(name,"",-1);
} 
window.onload = function(){
    if(checkCookie(window.cookieName) != window.cookieValue){
        createDiv(); 
    }
}
function removeMe(){
    var element = document.querySelector('.cookies-banner');
    element.parentNode.removeChild(element);
}